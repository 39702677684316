import { HostListener, Injectable, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, interval, Observable, Subscription, timer } from 'rxjs';
import { TimeoutpopupComponent } from '../../qadnomination/pages/timeout/timeoutpopup/timeoutpopup.component';

@Injectable({
  providedIn: 'root'
})
export class TimeoutService implements OnInit {

  showPopup = false;
  private dialogRef!: MatDialogRef<TimeoutpopupComponent>;


  private idleTimeout = 60 * 60;
  private warningTime = 60 * 1;
  private timer!: any;
  private timerSubscription: any;
  public timeLeft$: BehaviorSubject<number> = new BehaviorSubject<number>(this.idleTimeout);

  private lastInteractionTimestamp!: number;

  private alertTime: number = 60 * 1000;

  isTimeoutState = false;
  private sessionTimer: any;
  private sessionTimeoutDuration: number = 60 * 60 * 1000;

  constructor(private router: Router, private dialog: MatDialog) {

  }
  ngOnInit(): void {

  }

  closePopup() {
    this.showPopup = false;
    this.dialog.closeAll();
  }

  resetSessionTimer(): void {
    clearTimeout(this.sessionTimer);
    this.startSessionTimer();
  }
  startSessionTimer(): void {
    this.sessionTimer = setTimeout(() => {
      this.handleSessionTimeout();
    }, this.sessionTimeoutDuration);

    //setTimeout(() => {
    //  if (!this.isTimeoutState) {
    //    this.opentimeoutPopup(this.alertTime);
    //  }
    //}, this.sessionTimeoutDuration - this.alertTime);

  }
  // opentimeoutPopup(remainingTimeInSec: number) {

  //this.showPopup = true;

  //  this.dialogRef = this.dialog.open(TimeoutpopupComponent, {

  //    disableClose: true,
  //    width: '300px',
  //    hasBackdrop: true,
  //  });

  //setTimeout(() => {
  //  if (this.showPopup) {
  //    this.closePopup();
  //    this.showPopup = false;
  //  }
  //}, this.alertTime);
  //}

  handleSessionTimeout(): void {
    window.location.href = '/session-timeout';
  }




  trackUserInteractions() {
    window.addEventListener('click', (event) => {
      if (this.showPopup) {
        event.preventDefault();
        event.stopPropagation();
      } else { this.startSessionTimer(); }
    });
    window.addEventListener('keydown', (event) => {
      if (this.showPopup) {
        event.preventDefault();
        event.stopPropagation();
      } else { this.startSessionTimer(); }
    });
    window.addEventListener('mousemove', (event) => {
      if (this.showPopup) {
        event.preventDefault();
        event.stopPropagation();
      } else { this.startSessionTimer(); }
    });

  }
}
