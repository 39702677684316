<div class="header freeze no-select" [ngClass]="isAdmin ? 'hasAdminAccess' : 'noAdminAccess'">
  <div class="navbar navbar-expand-sm top-nav pad">
    <div class="align-items-center">
      <img class="logo-img img-fluid" [src]="acnLogoSrc" [alt]="acnLogoAlt" />
      <div class="vertical-divider"></div>
      <span class="title-text">
        {{ commonService.constants.headerTitle }}
      </span>
    </div>
    <ng-template #notifContent let-modal>
      <div style="background-color: white; transform: translate(-16%, 180%); border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); position: absolute; display: flex; height: auto; flex-direction: column; width: 750px; pointer-events: auto; background-clip: padding-box; border: 1px solid rgba(0,0,0,.2); outline: 0;">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title" style="font-weight: 600; font-family: Graphik; font-size: 16px; width: 100%; margin: 3px 0px;">Notifications</h4>
          <button mat-icon-button aria-label="Close" (click)="closeNotifPopupDialogue()" class="close" style="color:black !important">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="modal-body" id="notifmodel" style="font-size: 16px; font-family: Graphik; font-weight: 400; color: #555;">
          {{notificationTextContent}}
        </div>
      </div>
    </ng-template>
    <div class="align-items-center">
      <!--<div class="imageMenu" style="right: 13px; cursor: pointer; margin-top: 13px;" >-->
      <button class="imageMenuHelp" [ngClass]="{ open: isHelpModalOpen }" id="helpButton"
              [ngbPopover]="popOverHelp" popoverClass="help-popover" [autoClose]="'outside'"
              triggers="manual" #popOverHelps="ngbPopover"
              (click)="$event.stopPropagation(); openHelpPopup()">
        <img [src]="supportIconSrc" style="position: absolute; right: -26px; bottom: -26px;" />
      </button>
      <ng-template #popOverHelp>
        <div style="width:305px;text-align:left;">
          <span style="font-size:16px;color:#555555;font-family:Graphik;font-weight:500;position:relative;">Support Center</span>
          <div style="font-size:16px;color:#555555;padding:0px;margin:10px 10px;font-family:Graphik;">
            <span style="margin-top: 10px; margin-left: 10px;font-weight: 500;">Need Help?</span>
            <div style="margin:10px 0px 20px 10px;">
              <table *ngIf="isheaderDataLoaded">
                <tr *ngFor="let item of supportSectionValues; let i=index">
                  <td style="padding-top:6px;">
                    <a *ngIf=" i != supportSectionValues.length-1 && hideSupportLink(item)" href={{item.SupportLinkDesc}} target="_blank" style="font-weight: 400; color:#337ab7">{{item.SupportDesc}}</a>
                    <a *ngIf=" i != supportSectionValues.length-1 && item.SupportDesc == 'Submit Questions'" [href]="'mailto:'+ feedbackLink + '?subject= QAD Nomination Questions'" style="font-weight: 400; color:#337ab7">{{item.SupportDesc}}</a>
                  </td>
                </tr>
              </table>

            </div>
            <span style="margin-top: 10px; margin-left: 10px;font-weight: 500;">Have Feedback?</span>
            <div style="margin:10px 0px 20px 10px;">
              <table *ngIf="isheaderDataLoaded">
                <tr><td style="padding-top:6px;"><a ngif="supportSectionValues.length>0" [href]="'mailto:'+ feedbackLink + '?subject= QAD Nomination Feedback'" target="_blank" style="font-weight: 400; color: #337ab7">{{feedbackTitle}}</a></td></tr>
              </table>
            </div>
            <div style="margin:10px 0px 20px 10px;">
              <table *ngIf="isheaderDataLoaded">

                <tr>
                  <td style="padding-top:6px;">
                    <a href="javascript:void(0)" style="font-weight: 400; color:#337ab7;border:none;background-color:transparent;margin-left: -5px;" (click)="applicationLogout()">Logout</a>                 </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </ng-template>

      <!--<div class="imageMenu" style="right: 132px; cursor: pointer; margin-top: 13px;">-->
      <button class="imageMenuNot" [ngClass]="{ open: isNotificationModalOpen }" id="notButton"
              [ngbPopover]="popOverNotification" popoverClass="not-popover" [autoClose]="'outside'"
              triggers="manual" #popOverNotifications="ngbPopover"
              (click)="$event.stopPropagation(); openNotPopup()">
        <img [src]="notificationSrc" style="position: absolute; right: -26px; bottom: -26px;" />
      </button>
      <ng-template #popOverNotification>
        <span style="font-size: 16px; color: #555555; position: relative; font-family: Graphik; font-weight: 600;">Notifications</span>
        <div *ngIf="notifCount,else elseBlock">
          <div style="width: auto; height: 1px; border-top: 2px solid #eee; box-sizing: border-box; margin-left: -13px; margin-right: -13px"></div>
          <div *ngFor="let notification of notificationValues, let i = index">
            <div style="font-size: 14px; color: #c2c2ca; padding: 0px; margin: 3px 10px;font-weight:700; height:20px;font-family:Graphik">
              <span style="text-align:left;position:absolute;left:13px">{{notification.DateDiff}}</span>
              <span style="float:right;position:absolute;right:13px">{{notification.CreateDt}}</span>
            </div>
            <div style="margin:15px;margin-left:10px;">
              <span style="font-size: 16px; color: #000000; font-family: Graphik" (click)="FillNotificationBox(i,notification.NotificationTxt,notification.ReadStatus)"><a style="font-weight: 400; color: #337ab7" onclick="javascript:return false" href="#">{{notification.TitleNotificationTxt}}</a></span>
            </div>
            <input type="hidden" id="{{'hidnotificationTxt'+i}}" value="{{notification.NotificationTxt}}" />
            <input type="hidden" id="{{'hidNotificationId'+i}}" value="{{notification.NotificationID}}" />
            <!--</div>-->
          </div>
        </div>
        <ng-template #elseBlock style="display:block">
          <div style="width: auto; height: 1px; border-top: 2px solid #eee; box-sizing: border-box; margin-left: -13px; margin-right:-13px"></div>
          <div style="width: 376px; font-weight: 400; min-height: 20px; color: #555555;font-size:13px;margin-left:10px"><i>You have no active Notifications at this time.</i></div>
        </ng-template>
      </ng-template>
      <img id="indnotification" style="right: 117px; top: 2px;pointer-events:auto;position: absolute;margin-top:-20px;height:12px" [style.display]="this.readCount>0?'inline-block':'none'" [src]="reddot" />

      <!--<div class="imageMenu" style="right: 72px; cursor: pointer; margin-top: 13px;" >-->
      <button class="imageMenuTools" [ngClass]="{ open: isToolsOpen }" id="toolsButton"
              [ngbPopover]="popOverTools" popoverClass="tool-popover" [autoClose]="'outside'"
              triggers="manual" #popOverTool="ngbPopover"
              (click)="$event.stopPropagation(); openToolsPopup()">
        <img [src]="additionalToolsSrc" style="position: absolute; right: -26px; bottom: -26px;" />
      </button>
      <ng-template #popOverTools>
        <div style="width:305px;text-align:left;">
          <span style="font-size: 16px; color: #555555; font-family: Graphik; font-weight: 500; position: relative;">Additional Tools</span>
          <table style="font-size:16px;padding:0px;vertical-align:middle;margin:0px 25px 9px 10px;">

            <tr *ngFor="let tool of additionalToolValues">
              <td style="padding-left:4px;height:30px;" nowrap=nowrap><a target="_blank" href={{tool.ToolsLink}} style="font-weight: 400; color: #337ab7">{{tool.Tools}}</a></td>
            </tr>

          </table>
        </div>
      </ng-template>
    </div>
  </div>
</div>

