import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  textPrivacyPolicy: string = '';
  textCopyright: string = '';
  isAdmin = false;

  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    this.textPrivacyPolicy = this.commonService.constants.footerPrivacyPolicy;
    this.textCopyright = this.commonService.constants.footerCopyright;
    setTimeout(() => {
      this.commonService.checkUserRole().subscribe((usr: string) => {
        if (usr === "Administrator" || usr === "Reviewer" || usr === "Nominee" || usr === "ReadOnly") {
          this.isAdmin = true;
        }
      });
    }, 2000);
  }

}
