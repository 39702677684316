import { Inject, Injectable } from '@angular/core';
import { AppConfigService } from '../services/app-config.service';
import { RebarAuthModule } from './rebar.auth.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { environment } from '../../../environments/environment';
import { Observable, Subject, of } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { rejects } from 'assert';
import { ActivatedRoute, NavigationStart, Params, Router } from '@angular/router';
import { EnteredUrlService } from '../services/urlParamsService';

@Injectable({
  providedIn: RebarAuthModule,
})
export class RebarAuthService {
  private readonly destroying$ = new Subject<void>();
  public authObserver$: Observable<boolean>;
  configData: any;
  private currentUrl!: string;
  private readonly max_refresh_attempts = 3;
  private refreshAttempts = 0;
  private logoutSubject = new Subject<void>();
  private logoutKey = 'userLogout';
  constructor(
    config: AppConfigService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private auth: MsalService, private enteredUrlService: EnteredUrlService,
    private msalBroadcastService: MsalBroadcastService, private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute
  ) {

    if (this.authenticationEnabled()) {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          ),
          takeUntil(this.destroying$)
        )
        .subscribe(() => {
          this.checkAndSetActiveAccount();
          if (this.authenticationEnabled() && !this.isUserAuthenticated()) {
            this.login();
          }
          else {
            this.postLoginRedirect();
          }
        });
      this.authObserver$ = this.msalBroadcastService.inProgress$.pipe(
        map((status) => {
          return status === InteractionStatus.None;
        }),
        takeUntil(this.destroying$)
      );
    } else {
      this.authObserver$ = of(true);
    }

    this.configData = config.getConfig();

  }
  public postLoginRedirect(): void {
    //const enteredUrl = localStorage.getItem('enteredUrl');
    const enteredUrl = window.location.href;

    if (enteredUrl) {
      //  localStorage.removeItem('enteredUrl');

      const enterpriseIdIndex = enteredUrl.lastIndexOf('/');
      const enterpriseid = enteredUrl.substring(enterpriseIdIndex + 1);

      const isLocalhost = enteredUrl === this.configData.msal.auth.redirectUri;
      setTimeout(() => {
        if (isLocalhost || enterpriseid === null) {
          this.router.navigate(['/admin']);
        } else {

          if (enterpriseid !== null) {
            this.router.navigate([enterpriseid]);
          }
        }
      }, 1000);
    }

    else if (this.configData.msal.auth.redirectUri) {
      this.router.navigate(['/admin']);
    }

  }

  public isUserAuthenticated(): boolean {
    return this.auth.instance.getAllAccounts().length > 0;
  }

  public authenticationEnabled(): boolean {
    return environment.providers !== 'mock';
  }

  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.auth.instance.getActiveAccount();

    if (!activeAccount && this.auth.instance.getAllAccounts().length > 0) {
      const accounts = this.auth.instance.getAllAccounts();
      this.auth.instance.setActiveAccount(accounts[0]);

    }
  }

  public async login() {
    if (this.authenticationEnabled()) {
      const activeAccount = this.auth.instance.getActiveAccount();
      if (!activeAccount) {
        const enteredUrl = this.enteredUrlService.getEnteredUrl();
        if (enteredUrl) {
          localStorage.setItem('enteredUrl', enteredUrl);
        }
        if (this.auth.instance.getAllAccounts().length > 0) {
          const accounts = this.auth.instance.getAllAccounts();
          this.auth.instance.setActiveAccount(accounts[0]);
        } else {
          // if (this.msalGuardConfig.authRequest) {
          //   this.auth.loginRedirect({
          //     ...this.msalGuardConfig.authRequest,
          //   } as RedirectRequest);
          // } else {
          //   this.auth.loginRedirect();

          //JLBG starts
          this.auth.instance.handleRedirectPromise().then(async authResult => {
            // Check if user signed in
            const account = this.auth.instance.getActiveAccount();
            if (!account) {
              // redirect anonymous user to login page
              this.msalBroadcastService.inProgress$
                .pipe(
                  filter((status: InteractionStatus) => status === InteractionStatus.None),

                  takeUntil(this.destroying$)
                )
                .subscribe(() => {
                  this.auth.instance.loginRedirect();
                })
            } else {
              this.auth.instance.setActiveAccount(account);
            }
          }).catch(err => {
            // TODO: Handle errors
          });
        }
        //JLBG end
      }
    }
    else {
      this.checkAndSetActiveAccount();
    }
  }
  public logout(): void {
    this.auth.logout();
  }
  public logoutRedirect(logoutRequest: any): void {
    this.auth.logoutRedirect(logoutRequest);
   
  }
  public applogout() {
    localStorage.removeItem('apitokenexpiry');
    localStorage.removeItem('apitoken');
    sessionStorage.removeItem('msal.idtoken');
    sessionStorage.removeItem('ddrumsdk.tokenclaims');
    sessionStorage.removeItem('ddrumsdk.idtoken');
    localStorage.setItem(this.logoutKey, 'true');
    const accounts = this.auth.instance.getAllAccounts();
    this.auth.instance.setActiveAccount(accounts[0]);

    const logoutRequest = {
      account: this.auth.instance.getAllAccounts()[0]
    }

    setTimeout(() => {
      try {
        localStorage.setItem(this.logoutKey, 'true');
        this.logoutRedirect(logoutRequest);
        this.logoutSubject.next();
      } catch (error) {
        console.error('Error setting localStorage:', error);
      }
    }, 1000);
  }
  onLogout(): Observable<void> {
    return this.logoutSubject.asObservable();
  }
  isLoggedOut(): boolean {
    return localStorage.getItem(this.logoutKey) === 'true';
  }
  public getUser(): string | undefined {
    return this.auth.instance.getAllAccounts()[0]?.username;
  }
  public getUserClaims(): undefined | unknown {
    //e.g this.rebarAuthService.getUserClaims().name
    return this.auth.instance.getAllAccounts()[0]?.idTokenClaims;
  }
  public refreshToken() {
    const promise = new Promise<void>(async (resolve, rejects) => {
      await this.auth.instance.acquireTokenSilent({
        scopes: [this.configData.msal.auth.clientId + "/.default"]
      }).then((res: any) => {

        //console.log("expiry + token : ", res.expiresOn + ' ', res.expiresIn + ' ', res.accessToken, ' ', res?.idToken);
        localStorage.setItem('apitokenexpiry', res?.expiresOn);
        localStorage.setItem('apitoken', res?.accessToken)
        sessionStorage.setItem('ddrumsdk.idtoken', res?.idToken);
        resolve();
      })

    }).catch((error) => {
        if (error.errorMessage && error.errorMessage.includes('monitor_window_timeout')) {
         if (this.refreshAttempts < this.max_refresh_attempts) {
            this.refreshAttempts++;
          setTimeout(() => {
              this.refreshToken();
            }, 2000);
          }
          else {
            console.error('Exceeded max token refresh attempts for monitor window timeout');
          }
        } else {
          console.error('Token acquisition error:', error);
        }
      //rejects(error);
    });
  }
  public getJwt() {
    return this.extractMSALToken();
  }

  private extractMSALToken() {
    let timestamp = null;
    let token = null;

    // look for session storage based on the keys
    for (const key of Object.keys(sessionStorage)) {
      // weird behavior of msal to create multiple session variables with keys that looks like an object
      // these variables contains tokens with different expirations
      if (key.includes('"authority":')) {
        const val: any = JSON.parse(sessionStorage.getItem(key)!) // retrieve the session variable based on the current key

        // if the token is null assign the current accesstoken and expiration
        if (!token) {
          token = val.accessToken;
          timestamp = val.expiresIn;
        }
        else {
          // replace the token and expiration if the current session variable has a later expiration
          if (val.expiresIn > timestamp) {
            token = val.accessToken;
            timestamp = val.expiresIn;
          }
        }
      }
    }

    // return the token if it is not null
    if (token) return token

    // return the msal.idtoken if there is no access token stored
    return sessionStorage.getItem('msal.idtoken');
  }

  refreshTokenObservable(): Observable<string> {

    return new Observable(observer => {

      this.auth.instance.acquireTokenSilent({

        scopes: [this.configData.msal.auth.clientId + "/.default"]

      }).then((res: any) => {

        localStorage.setItem('apitokenexpiry', res?.expiresOn);

        localStorage.setItem('apitoken', res?.accessToken)

        sessionStorage.setItem('ddrumsdk.idtoken', res?.idToken);

        observer.next(res.accessToken); // Emit the new token

        observer.complete(); // Complete the observable

      }).catch(error => {

        observer.error(error); // Handle token refresh failure

      });

    });

  }

}

