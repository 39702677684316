
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import { RebarAuthService } from '../rebarauth/rebar.auth.service';
import { CommonService } from "./common.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private activatedRoute: ActivatedRoute,  private apiService: CommonService, private rebarAuthService: RebarAuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
   
    return this.apiService.checkUserRole().pipe(map((usr: string) => {
      if (usr === "NoAccess" || usr === null) {

        this.router.navigate(['/not-authorized']);
        return false;
      }
      else {
        return true;
      }

    }));

  }

}
