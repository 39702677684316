import { Component, HostListener } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { RebarAuthService } from './core/rebarauth/rebar.auth.service';
import { AuthenticationResult } from '@azure/msal-browser';
// import moment from 'moment';
import * as moment from "moment-timezone";

/*import { TimeoutpopupComponent } from './qadnomination/pages/timeout/timeoutpopup/timeoutpopup.component';*/
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TimeoutService } from './core/services/TimeoutService';
import { AppTimeoutService } from './core/services/AppTimeoutService';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'QADNomination_Angular_4471';
  private renewalThresholdMinutes = 3;
  private logoutKey = 'userLogout';
  constructor(private rebarAuthService: RebarAuthService,
    private authService: MsalService, private dialog: MatDialog, private timeoutservice: TimeoutService, private apptimeoutService: AppTimeoutService) {

  }

  ngOnInit(): void {
    // localStorage.setItem(this.logoutKey, 'false');
    this.apptimeoutService.onActivity();
    //this.timeoutservice.startSessionTimer();
    //this.timeoutservice.trackUserInteractions();
    // this.renewTokenIfCloseToExpiring();
    if (this.rebarAuthService.authenticationEnabled()) {
      //this.rebarAuthService.logout();
      if (!this.rebarAuthService.isUserAuthenticated()) {

        this.authService.handleRedirectObservable().subscribe({
          next: (result: AuthenticationResult) => {
            // Perform actions related to user accounts here
            if (result === null) {
              this.rebarAuthService.login();
              this.redirect();
            } else {
              this.redirect();
            }


            //Add these 2 lines of code for storing the User Token Claims in the session Storage
            const userTokenClaims = JSON.stringify(
              this.rebarAuthService.getUserClaims()
            );
            const parsedClaims = JSON.parse(userTokenClaims);
            const payload = {
              businessOrg: parsedClaims.businessOrg,
              countryDescription: parsedClaims.countryDescription,
              countryCode: parsedClaims.countryCode,
              jobTitle: parsedClaims.jobTitle,
              jobFamily: parsedClaims.jobFamily,
              location: parsedClaims.location,
              locationCode: parsedClaims.locationCode,
              jobFamilyDescription: parsedClaims.jobFamilyDescription,
            };
            //Set Item to Session Storage
            sessionStorage.setItem('ddrumsdk.tokenclaims', JSON.stringify(payload));

          },

          error: (error) => console.log(error)

        });

      } else {

        this.redirect();

      }

    }

    if (localStorage.getItem('apitokenexpiry') != undefined || localStorage.getItem('apitokenexpiry') != null) this.isTokenExpired();
  }

  redirect() {
    this.rebarAuthService.checkAndSetActiveAccount();
    this.rebarAuthService.refreshToken();
    this.renewTokenIfCloseToExpiring();
  }
  //restartTimer() {
  //  this.timeoutservice.startSessionTimer();
  //}
  isTokenExpired() {
    const timeRemaining = this.getTimeRemainingUntilExpiration();
    setTimeout(() => {
      this.rebarAuthService.refreshToken();
    }, (timeRemaining));
  }


  getTimeRemainingUntilExpiration(): number {
    if (!localStorage.getItem("apitoken")) {
      return 0;
    }
    const tokenexpirytime: any = localStorage.getItem('apitokenexpiry');
    const expirationTime = new Date(tokenexpirytime).getTime();
    const currentTime = new Date().getTime();
    const timeRemaining = Math.max(0, expirationTime - currentTime) / (1000 * 60);
    return timeRemaining;
  }

  // Renew the token if it's close to expiration
  renewTokenIfCloseToExpiring(): void {
    const timeRemaining = this.getTimeRemainingUntilExpiration();
    if (timeRemaining <= this.renewalThresholdMinutes) {
      this.rebarAuthService.refreshToken();
      setTimeout(() => {
        this.renewTokenIfCloseToExpiring();
      }, timeRemaining * 60 * 1000);
    } else {

      setTimeout(() => {
        this.renewTokenIfCloseToExpiring();
      }, timeRemaining * 60 * 1000);
    }
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keypress', ['$event'])
  onUserInteraction(event: MouseEvent | KeyboardEvent): void {
    // Restart the timeout whenever there's user activity
    this.apptimeoutService.onActivity();
  }
}

