import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigService } from './core/services/app-config.service';
import { APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalRedirectComponent, MsalModule } from '@azure/msal-angular';
import { RebarAuthModule } from './core/rebarauth/rebar.auth.module';
import { HashLocationStrategy, LocationStrategy, APP_BASE_HREF, NgFor, PathLocationStrategy} from '@angular/common';
import { HeaderComponent } from './qadnomination/header/header.component';
import { FooterComponent } from './qadnomination/footer/footer.component';
import { LayoutComponent } from './qadnomination/layout/layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminPageComponent } from './qadnomination/pages/admin-page/admin-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ExportComponent } from './qadnomination/pages/admin-page/export/export.component';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import { QadnominationFormComponent } from './qadnomination/pages/qadnomination-form/qadnomination-form.component';
import {MatStepperModule  } from '@angular/material/stepper';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import { MatTreeModule} from "@angular/material/tree";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { AuthInterceptor } from './core/services/auth.interceptor';
import { CreatenominationformComponent } from './qadnomination/pages/admin-page/createnominationform/createnominationform.component';
import { EqadvalidationformComponent } from './qadnomination/pages/admin-page/eqadvalidationform/eqadvalidationform.component';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';

import * as moment from 'moment';
import { NotAuthorizedComponent } from './qadnomination/pages/not-authorized/not-authorized.component';
import { AppInitializerService } from './core/services/app-initializer.service';
import { EnteredUrlService } from './core/services/urlParamsService';
import { UserIdleModule } from 'angular-user-idle';
import { TimeoutComponent } from './qadnomination/pages/timeout/timeout.component';
import { TimeoutpopupComponent } from './qadnomination/pages/timeout/timeoutpopup/timeoutpopup.component';


@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, LayoutComponent, AdminPageComponent, ExportComponent, QadnominationFormComponent, CreatenominationformComponent, EqadvalidationformComponent, NotAuthorizedComponent, TimeoutComponent, TimeoutpopupComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RebarAuthModule.forRoot(),
    MsalModule,
    BrowserAnimationsModule,
    FormsModule,
    NgbModule,
    AgGridModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
    MatStepperModule,
    MatIconModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatTreeModule,
    MatCheckboxModule,
    MatDialogModule,
    FormsModule,
    NgFor,
    MatAutocompleteModule,
    MatChipsModule,
    MatTooltipModule,
    //UserIdleModule.forRoot({ idle: 3300, timeout: 300, ping: 120 }) //5min=300s to reset; 55mins=3300s for user idle; total of 60mins=3600s; 

  ],
  providers: [
    EnteredUrlService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializerService: AppInitializerService) => () => appInitializerService.initializeApp(),
      deps: [AppInitializerService],
      multi: true,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
   // {
     // provide: LocationStrategy, useClass: HashLocationStrategy
    //},
    {
      provide: APP_BASE_HREF,
      useValue: ''
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfigService) => () => config.load(),
      deps: [AppConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],

})
export class AppModule {
}
