
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnteredUrlService {
  enteredUrl!: string;

  setEnteredUrl(url: string): void {
    this.enteredUrl = url;
  }

  getEnteredUrl(): string {
    return this.enteredUrl;
  }
}
