
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, catchError, concatMap, map, Observable, of, Subject } from 'rxjs';
import { AppConfigService } from '../services/app-config.service';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';

import { environment } from '../../../environments/environment';
import { getNominationForm } from '../models/NominationFormData';

import { ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  currentusername?: string;
  isAdmin$ = new BehaviorSubject<boolean>(false);
  hasAdminAccess$ = this.isAdmin$.asObservable();
  isAdmin = new BehaviorSubject<boolean>(false);
  isNominee = new BehaviorSubject<boolean>(false);
  isReviewer = new BehaviorSubject<boolean>(false);
  isReadOnly = new BehaviorSubject<boolean>(false);
  userRole?: any;
  backUpAdminGridData = new BehaviorSubject<any>([]);
  getBackUpAdminGridData$ = this.backUpAdminGridData.asObservable();
  gridbatchData: any = [];
  gridResultdata$!: Observable<any>;
  configData: any;

  constructor(
    private http: HttpClient,
    config: AppConfigService,
    private rebarAuthService: RebarAuthService,
    private router: Router
  ) {
    this.configData = config.getConfig();
   
  }

  callAdminApiRequest(endpoint: string, params?: any, body?: any): Observable<any> {
    const url = environment.baseAdminApiURL + endpoint;
    let headers = new HttpHeaders();

    // Add any custom headers if needed
    // headers = headers.set('Access-Control-Allow-Methods','POST' );
    let httpParams = new HttpParams();
    httpParams = params;

    const options = {
      headers,
      params: httpParams,
      body
    };

    return this.http.request<any>('post', url, options);
  }
  callNominationApiRequest(endpoint: string, params?: any, body?: any): Observable<any> {
    const url = environment.baseNominationApiURL + endpoint;
    let headers = new HttpHeaders();

    // Add any custom headers if needed
    // headers = headers.set('Access-Control-Allow-Methods','POST' );
    let httpParams = new HttpParams();
    httpParams = params;

    const options = {
      headers,
      params: httpParams,
      body
    };

    return this.http.request<any>('post', url, options);
  }

  getCurrentUserRole(params: any): Observable<any> {

    return this.callAdminApiRequest('rolelevelaccess', params);
  }
  checkUserRole(): Observable<any> {
    let eid = this.getCurrentUsername();
    const paramEID = { EID: eid };
    return this.getCurrentUserRole(paramEID).pipe(
      map(user => {
        let usrRoles = 'NoAccess';
        if (user.length > 0) {
          if (user[0].RoleCd === 0) {
            usrRoles = 'NoAccess';
          }
          else {
            usrRoles = user[0].RoleDesc;
          }

        }
        return usrRoles;
      }
      ));
  }
  
  getNominationFormData(params: any): Observable<getNominationForm> {

    return this.callNominationApiRequest('formcreateqad', params);


  }
  getNominationFormTimeline(params: any): Observable<any> {
    return this.callNominationApiRequest('formtimeline', params);

  }

  getAreaofExpertise(params: any): Observable<any> {
    return this.callNominationApiRequest('areasofexpertisedata', params);
  }


  getSkills(params: any): Observable<any> {
    return this.callNominationApiRequest('skilltreeviewdata', params);

  }

  getCurrentUsername(): any {
    this.currentusername = this.rebarAuthService.getUser();
    return this.currentusername = this.currentusername?.substring(0, this.currentusername.indexOf('@'));
  }
  postNominationFormData(postdata: any): Observable<any> {
    return this.callNominationApiRequest('nominationformeditdata', postdata);
  }
  public constants: any = {
    footerPrivacyPolicy: 'Privacy Policy',
    footerCopyright:
      '© 2001 - 2021 Accenture. All rights reserved. Accenture Highly Confidential. For internal use by Authorized Personnel only.',
    headerTitle: 'QAD Nomination',
    adminFilterSubmitDate: '01-10-2023',
    adminDefaultColDef: {
      sortable: true,
      filter: false,
      wrapText: false,
      autoHeight: true,
      editable: false,
      resizable: true,
      flex: 1,
      minWidth: 100,
      suppressNavigable: true,
      context: {
        suppressSelection: true,
        enableColResize: true,
        cacheBlockSize: 100,
        suppressRowClickSelection: true,
      },
    },
  };

  getAdminGridData(params: any): Observable<any> {
    return this.callAdminApiRequest('admingriddata', params);
  }
 
  gridbatchprocessData(params: any, minRowsize: number, maxRowsize: number): Observable<any> {
    const minRow = minRowsize + 1;
    const updatedParams = {
      ...params,
      MinRowCount: minRow,
      MaxRowCount: maxRowsize,
    };

    return this.getAdminGridData(updatedParams).pipe(
      concatMap((griddata: any) => {
        if (griddata && griddata.length > 0) {
          this.gridbatchData.push(...griddata);
          const batchsizeIncrement = this.configData.gridbatchSize;
          const nextminRow = maxRowsize;
          const nextmaxRow = maxRowsize + batchsizeIncrement;
          if (griddata.length < batchsizeIncrement) {
            this.gridResultdata$ = this.gridbatchData;
            this.gridbatchData = [];
            return of(this.gridResultdata$);
          }
          else {
          
            return this.gridbatchprocessData(params, nextminRow, nextmaxRow);
          }

        } else {

          this.gridResultdata$ = this.gridbatchData;
          this.gridbatchData = [];
          return of(this.gridResultdata$);
        }
      })
    );

  }

  getgridupdatedtimeflag(): Observable<any> {
    let eid = this.getCurrentUsername();
    const params = { AdminUser: eid };
    return this.callAdminApiRequest('timestampdata',params);
  }


  public userEID = '';
  getUserTokenFromMSAL(): string {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (localStorage.getItem(key!)?.match('AccessToken')) {
        this.userEID = localStorage.getItem(key!)?.split('"secret":"')[1]!;
        this.userEID = this.userEID.split('"')[0];
      }
    }

    return this.userEID;
  }

  generateToken(
    tokenUrl: string,
    clientId: string,
    clientSecret: string,
    grantType: string
  ): any {
    const body = new URLSearchParams();
    body.set('grant_type', grantType);
    body.set('client_id', clientId);
    body.set('client_secret', clientSecret);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post(tokenUrl, body, { headers });
  }

  columnDefs!: any;
  adminColDef(rowData$: any): any {
    rowData$.forEach((element: any) => {
      this.columnDefs = {
        adminColDefs: [
          {
            headerName: 'Nominee\'s Enterprise ID',
            field: 'NomineeEID',

            context: {
              orderNbrGrid: 1
            },


            // cellClass: 'locked-col',
            // suppressMovable: true,
            lockPosition: 'left',
            lockVisible: true,
            cellClass: 'locked-visible',
            suppressMovable: true,
            cellRenderer: function (a: ICellRendererParams) {
              return `<a href="/#/${a.value}" style="color:#a100ff;" target="_blank">${a.value}</a>`;
            },
          },
          {
            headerName: 'Form Status', field: 'FormStatusDesc',

            context:
              { orderNbrGrid: 2 },
           
          },
          {
            headerName: 'QAD or EQAD', field: 'CertificationTypeDesc',
           
            context:
              { orderNbrGrid: 3},
            
          },
          {
            headerName: 'Nominee\'s Market', field: 'GeographicRegionDesc',
            
            context:
              { orderNbrGrid: 4 },
           
          },
          {
            headerName: 'Nominee\'s Market Unit', field: 'GeographicUnitDesc',
       
            context:
              { orderNbrGrid: 5 },
           
          },
          {
            headerName: 'Market Unit Quality Risk Lead Enterprise ID',
           
            context:
              { orderNbrGrid: 6 },
          },
          {
            headerName: 'Nominator\'s Enterprise ID', field: 'NominatorEID',
          
            context:
              { orderNbrGrid: 7 },
         
          },
          {
            headerName: 'Created Date', field: 'FormCreateDttm',
            context:
              { orderNbrGrid: 8 },
         
          },
          {
            headerName: 'Areas of Expertise', field: 'AreasofExpertise',
        
            context:
              { orderNbrGrid: 9 },
            
          },
          {
            headerName: 'Skills', field: 'ExpertiseSkills',
           
            context:
              { orderNbrGrid: 10 },
           
          },
        ],
      };
    });

    return this.columnDefs.adminColDefs;
  }

  getAdminGridFiltersData(params: any): Observable<any> {
    return this.callAdminApiRequest('admingridfiltersdata', params);
  }

  getColumnFieldsData(): Observable<any> {
    let eid = this.getCurrentUsername();
    const params = { EID: eid };
    return this.callAdminApiRequest('columnfieldsdata', params);
  }

  getNomineeDetails(params: any): Observable<any> {
    return this.callNominationApiRequest('nominationformnomineeselectdata', params);
  }

  getNominatorDetails(params: any): Observable<any> {
    return this.callNominationApiRequest('nominationformnominatorselectdata', params);
  }

  getNomineeEID(params: any): Observable<any> {
    return this.callNominationApiRequest('nominationnomineepickerselectdata', params);
  }

  getNominatorEID(params: any): Observable<any> {
    return this.callNominationApiRequest('nominationnominatorpickerselectdata', params);
  }
  saveNomineeDetails(params: any): Observable<any> {
    return this.callNominationApiRequest('nominationformcreatedata', params);
  }
  getHeaderSupportSection(): Observable<any> {
    let eid = this.getCurrentUsername();
    const params = { EID: eid };
    return this.callNominationApiRequest('nominationsupportcenterselectdata', params);
  }
  getNotificationsSection(params: any): Observable<any> {
    return this.callNominationApiRequest('notificationsbyenterpriseidselectdata', params);
  }

  setNotificationReadStatus(params: any): Observable<any> {
    return this.callNominationApiRequest('notificationenterpriseidtrackinginsertdata', params);
  }
  getAdditionalToolsSection(): Observable<any> {
    let eid = this.getCurrentUsername();
    const params = { EID: eid };
    return this.callNominationApiRequest('nominationadditionaltoolsselectdata', params );
  }
  getNomineeIndustrySegment(): Observable<any> {
    let eid = this.getCurrentUsername();
    const params = { AdminUser: eid };
    return this.callAdminApiRequest('nominationformnomineeindustrysegmentselectdata', params);
  }
  async getAdminAccessState() {
    var hasAdminAccess = false;
    const paramEID = { EID: await this.getCurrentUsername() };
    this.getCurrentUserRole(paramEID).subscribe(
     async (response: any) => {
        if (response.length > 0) {
          this.userRole = await response[0].RoleDesc || await response.RoleDesc;

          if (await this.userRole) {
            if ((await this.userRole) == 'Administrator') {
              this.isAdmin.next(true);
              hasAdminAccess = true;
            } else if ((await this.userRole) == 'Nominee') {
              this.isNominee.next(true);
              hasAdminAccess = false;
            } else if ((await this.userRole) == 'Reviewer') {
              this.isReviewer.next(true);
              hasAdminAccess = true;
            } else if ((await this.userRole) == 'Read Only') {
              this.isReadOnly.next(true);
              hasAdminAccess = true;
            }
          }

          if (this.isAdmin.value || this.isReviewer.value || this.isReadOnly.value) {
            this.isAdmin$.next(true);
            this.router.navigate(['/admin']);
          } else {
            this.isAdmin$.next(false);
            this.router.navigate(['/not-authorized']);
          }
        } else {
          this.isAdmin$.next(false);
          this.router.navigate(['/not-authorized']);
        }
      },
      (error: any) => {
        console.log('test error getAdminAccessState: ', error);
      }
    );
  }
  onNotHelpSupportOpen() {
    this.onNotHelpSupportOpenSource.next('');
  }
  private onNotHelpSupportOpenSource = new Subject<string>();
  nothelpsupportButtonClicked$ = this.onNotHelpSupportOpenSource.asObservable();
}
