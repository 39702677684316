import { Component, OnInit, ViewChild, ViewEncapsulation, Input, HostListener } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import moment from 'moment';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { AppConfigService } from '../../../../core/services/app-config.service';
@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ExportComponent implements OnInit {
  assetsAdmin = 'assets/img/admin/';
  exportIcon = this.assetsAdmin + 'export_icon.svg';
  isModalOpen: boolean = false;
  excelData: any = "";
  allselectedvalues: string[] = [];
  public lastCol: any = "";
  public exportParam: any = "";
  @Input() FormStatus: any;
  @Input() NomineesMarket: any;
  @Input() MarketUnits: any;
  @Input() SubmittedDateStart: any;
  @Input() SubmittedDateEnd: any;
  @Input() Certification: any;
  exportPopup: any = null;
  isLoading: boolean = false;
  @ViewChild('exportModal') public exportModal!: NgbPopover;
  configData: any;
  ngOnInit(): void { }
 

  constructor(private modalService: NgbModal, private commonService: CommonService, private config: AppConfigService) {
    this.configData = config.getConfig();
  }

  openVerticallyCentered(content: any) {
    this.isModalOpen = true;
    this.allselectedvalues = [];
    this.exportPopup =this.modalService.open(content, { windowClass: "exportwindowtemplate", centered: true });
  }

  closeVerticallyCentered() {
    this.isModalOpen = false;
    this.modalService.dismissAll(true);
  }

  formatSubmitDate(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }
 
  async exportReport() {
    this.isLoading = true;
   if (this.allselectedvalues.length == 0) {
      this.exportParam = {
        FormStatusCd: this.FormStatus == undefined ? 'ALL' : this.FormStatus,
       MarketCd: this.NomineesMarket == undefined ? 'ALL' : this.NomineesMarket,
       MUCd: this.MarketUnits == undefined ? 'ALL' : this.MarketUnits,
       CertCd: this.Certification == undefined ? 'ALL' : this.Certification,
        SubmitDateFROM: this.SubmittedDateStart == undefined ? 'ALL' : this.formatSubmitDate(this.SubmittedDateStart._d),
        SubmitDateTO: this.SubmittedDateEnd == undefined ? 'ALL' : this.formatSubmitDate(this.SubmittedDateEnd._d),
        AdminUser: await this.commonService.getCurrentUsername(),
        ColumnIds: "All"
      };
    }
    else {
       this.exportParam = {
         FormStatusCd: this.FormStatus == undefined ? 'ALL' : this.FormStatus,
       MarketCd: this.NomineesMarket == undefined ? 'ALL' : this.NomineesMarket,
       MUCd: this.MarketUnits == undefined ? 'ALL' : this.MarketUnits,
       CertCd: this.Certification == undefined ? 'ALL' : this.Certification,
         SubmitDateFROM: this.SubmittedDateStart == undefined ? 'ALL' : this.formatSubmitDate(this.SubmittedDateStart._d),
         SubmitDateTO: this.SubmittedDateEnd == undefined ? 'ALL' : this.formatSubmitDate(this.SubmittedDateEnd._d),
          AdminUser: await this.commonService.getCurrentUsername(),
          ColumnIds: (this.allselectedvalues.join(','))
      };
    }
    this.commonService.gridbatchprocessData(this.exportParam, this.configData.gridatchIndex, this.configData.gridbatchSize).subscribe(
      async (result: any) => {
        this.excelData = await result;
        let d = new Date();
        const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let date = d.getDate() + '-' + month[d.getMonth()] + '-' + d.getFullYear();
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('QADNomination_Report');
        let titleRow = worksheet.getCell('A1');
        titleRow.value = "QAD Nominations";
        worksheet.getRow(1).height = 19;
        worksheet.getRow(2).height = 19;
        worksheet.getRow(3).height = 19;
        titleRow.font = {
          name: 'Graphik',
          size: 14,
          underline: 'none',
          bold: true,
          color: { argb: '7500C0' }
        }
        titleRow.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
        let dateRow = worksheet.getCell('A2');
        dateRow.value = "Report As Of:" + date;
        dateRow.font = {
          name: 'Graphik',
          size: 10,
          underline: 'none',
          bold: true,
          color: { argb: '7500C0' }
        }
        dateRow.alignment = { vertical: 'middle', horizontal: 'left' }
        worksheet.mergeCells('A3', 'D3');

        let confidentialRow = worksheet.getCell('A3');
        confidentialRow.value = "***Restricted – For Company Internal Use by Authorized Personnel Only***";
        confidentialRow.font = {
          name: 'Graphik',
          size: 9,
          color: { argb: 'FF0000' }
        }
        confidentialRow.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
        worksheet.addRow([]);
        let headerrow = worksheet.addRow(Object.keys(this.excelData[0]));
        this.lastCol = Object.keys(this.excelData[0]).length;
        headerrow.height = 35;
        headerrow.eachCell((cell, number) => {
          cell.font = {
            name: 'Graphik',
            size: 10,
            color: { argb: '000000' }
          }
          cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } }
          }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'E5E5E5' },
          }
        });
        this.excelData.forEach((d: any) => {
          let dataRow = worksheet.addRow(Object.values(d));
          dataRow.eachCell((cell, number) => {
            let val = "" + cell.value + "";
            let regExp = /[0-9]/;
            if (isNaN(Date.parse(val)) == false && val.length == 11 && regExp.test(val.charAt(0)) == true && regExp.test(val.charAt(10)) == true) {
              const cellDate = new Date(val + " UTC") ; 
              cell.value = cellDate;
              cell.alignment = {
                wrapText: true,
                horizontal: 'center',
                vertical: 'middle'
              }
              cell.numFmt = 'dd-mmm-yyyy'; 
            }
            else {
              cell.alignment = {
                wrapText: true,
                horizontal: 'left',
                vertical: 'middle'
              }             
            }
          })
        });
        const lastRow = worksheet.rowCount;
        var range = { s: { r: 6, c: 1 }, e: { r: lastRow, c: this.lastCol } };
        for (var R = range.s.r; R <= range.e.r; ++R) {
          for (var C = range.s.c; C <= range.e.c; ++C) {
            let columnLetter = worksheet.getColumn(C).letter;
            let cell = worksheet.getCell(columnLetter + R);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFFF' },
            }
            cell.font = {
              color: { argb: '313131' },
              size: 10,
              name: 'Graphik'
            }
            cell.worksheet.properties.defaultColWidth = 25;
            cell.border = {
              top: { style: 'thin', color: { argb: '000000' } },
              right: { style: 'thin', color: { argb: '000000' } },
              bottom: { style: 'thin', color: { argb: '000000' } },
              left: { style: 'thin', color: { argb: '000000' } }
            }
          }
        }


        workbook.xlsx.writeBuffer().then((data: any) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, "QADNomination_Report" + '.xlsx');
        })
        setTimeout(() => {
          this.isLoading = false;
        }, 3000);
      },
      (error: any) => {
        console.log(
          'test error getAdminGridData() on exportReport(): ',
          error
        );
      });
  }

  checkboxchange(event: any) {
    const checkboxLabelValue = event.target.attributes[0].value;
    const checked = event.target.checked;
    if (checked == false) {
      if (this.allselectedvalues.includes(checkboxLabelValue)) {
        this.allselectedvalues = this.allselectedvalues.filter((item) => item !== checkboxLabelValue);
      } else {
        this.allselectedvalues.push(checkboxLabelValue);
      }
    }
    else {
      if (this.allselectedvalues.includes(checkboxLabelValue)) {
        const index = this.allselectedvalues.indexOf(checkboxLabelValue);
        this.allselectedvalues.splice(index, 1);
      }
    }
  }
   @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const targets = event.target as HTMLElement;
    if (targets.tagName == "LABEL") {
      if (this.exportPopup) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

}
