

export class getNominationForm {
  PeopleKey: number;
  NomineeEnterpriseId!: string ;
  NomineesMarket!: string;
  NomineesMarketUnit!: string;
  MarketUnitQRLEnterpriseID!: string;
  HeldPriorQADCertification: boolean;
  FormExist: number;
  FormLink: string;
  FormStatusDesc: string;
  FormStatusCd: number;
  FormStatusColor: string;
  RationaleNominationNotes!: string ;
  SCReviewDt: Date;
  "SCReconsiderDt": Date;
  "SCDecisionCd": number;
  "SCDecisionDesc": string;
  "RegistrationCanceledDt": Date;
  "TrainingEnrollmentDt": Date;
  "TrainingCompletionDt": Date;
  "SubmitDttm": Date;
  "QRLApprovedDttm": Date;
  "EQADCertificationDt": Date;
  "QADCertificationDt": Date;
  "SCDecisionNotes": string;
  "MasteryTestCompletionDt": Date;
  "CaseStudyAssessmentCompletionDt": Date;
  "NominatorEID": string;
  "NominatorMarketUnit": string;
  NominatorMarket!: string;
  "CertificationId": number;
  "CertificationTypeDesc": string;
  "CertificationLabel": string;
  "SessionDate": Date;
  "TrainingStatus": string;
  "ReactivateDttm": Date;
  "RegistrationCanceledDtBottomDisplay": Date;
  "NomineeIndustrySegment": string;
 

  NomineeExpertiseSkillIds!: string;
  NomineeAreasOfExpertiseIds!: string;
  constructor(data?: any) {
    this.PeopleKey = data.PeopleKey,
      this.NomineeEnterpriseId = data.EnterpriseId,
      this.NomineesMarket = data["Nominee’s Market"],
      this.NomineesMarketUnit = data["Nominee’s Market Unit"],
      this.MarketUnitQRLEnterpriseID = data["Market Unit QRL Enterprise ID"],
      this.HeldPriorQADCertification = data["Held Prior QAD Certification"],
      this.FormExist = data["FormExist"],
      this.FormLink = data["FormLink"],
      this.FormStatusDesc = data["FormStatusDesc"],
      this.FormStatusCd = data["FormStatusCd"],
      this.FormStatusColor = data.FormStatusColor,
      this.RationaleNominationNotes = data.RationaleNominationNotes,
      this.SCReviewDt = data.SCReviewDt,
      this.SCReconsiderDt = data.SCReconsiderDt,
      this.SCDecisionCd = data.SCDecisionCd,
      this.SCDecisionDesc = data.SCDecisionDesc,
      this.RegistrationCanceledDt = data.RegistrationCanceledDt,
      this.TrainingEnrollmentDt = data.TrainingEnrollmentDt,
      this.TrainingCompletionDt = data.TrainingCompletionDt,
      this.SubmitDttm = data.SubmitDttm,
      this.QRLApprovedDttm = data.QRLApprovedDttm,
      this.EQADCertificationDt = data.EQADCertificationDt,
      this.QADCertificationDt = data.QADCertificationDt,
      this.SCDecisionNotes = data.SCDecisionNotes,
      this.MasteryTestCompletionDt = data.MasteryTestCompletionDt,
      this.CaseStudyAssessmentCompletionDt = data.CaseStudyAssessmentCompletionDt,
      this.NominatorEID = data.NominatorEID,
      this.NominatorMarketUnit = data.NominatorMarketUnit,
      this.NominatorMarket = data.NominatorMarket,
      this.CertificationId = data.CertificationId,
      this.CertificationTypeDesc = data.CertificationTypeDesc,
      this.CertificationLabel = data.CertificationLabel,
      this.NomineeExpertiseSkillIds = data.NomineeExpertiseSkillIds,
      this.NomineeAreasOfExpertiseIds = data.NomineeAreasOfExpertiseIds,
      this.SessionDate = data.SessionDate,
      this.TrainingStatus = data.TrainingStatus,
      this.ReactivateDttm = data.ReactivateDttm,
      this.RegistrationCanceledDtBottomDisplay = data.RegistrationCanceledDtBottomDisplay;
      this.NomineeIndustrySegment = data.NomineeIndustrySegment;

  }
 
}

export class FormStatusTimeline {

  constructor(
    public FormStatusCd: number,
    public TimelineLabel: string,
    public TimelineFlag: boolean,
    public TimelineDesc: string
  ) { }
}



export interface SkillsTreeNode {
  indexPosition: number;
  id: number;
  skillsExpertiseNm: string;
  isChecked?: boolean;
  indeterminate?: boolean;
  children: SkillsTreeNode[];
  parent?: any;
  expanded?: boolean;
  disableSecSkillsFlag?: number;
}
//export interface SkillsCheckedNode {
//  id: number;
//  skillsExpertiseNm: string;
//}

    

