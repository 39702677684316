import { Injectable } from '@angular/core';
import { EnteredUrlService } from './urlParamsService';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(private enteredUrlService: EnteredUrlService) { }

  initializeApp(): Promise<void> {
    return new Promise<void>((resolve) => {
      // Preserve the entered URL in the EnteredUrlService
      this.enteredUrlService.setEnteredUrl(window.location.href);
      resolve();
    });
  }
}
