<button id="CreateEQAD" class="createEQADButton" type="button" (click)="openVerticallyCenteredEQAD(contenteqad)">Create EQAD Form</button>

<ng-template #contenteqad let-modal>
  <div class="container-fluid eqadvalidationform-modal">
    <div class="eqadvalidationform-header">
      <div class="eqadvalidationform-title">Elite QAD Form Validation</div>
      <div class="eqadvalidationform-subtitle">
        The nominee you entered has never held a QAD certification.
      </div>
      <div class="eqadvalidationform-subtitles">
        Click 'Continue' to confirm the MU Quality Lead has reviewed and approved this exception or click 'Cancel' to change to a QAD nomination.
      </div>
    </div>

    <button id="Cancel" type="button" class="canceleqad" (click)="closeVerticallyCenteredEQAD()">Cancel</button>

    <button id="Continue" class="submitEQADButton" style="position:relative;" (click)="createEQAD()" type="button">Continue</button>

  </div>
</ng-template>

