import { Component } from '@angular/core';
import { AppConfigService } from '../../../core/services/app-config.service';
import { TimeoutService } from '../../../core/services/TimeoutService';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.css']
})
export class TimeoutComponent {
  applicationurl: any = '';
  configData: any;
  constructor( private config: AppConfigService, private timeoutservice: TimeoutService) {
   
    this.configData = config.getConfig();
    this.applicationurl = this.configData.msal.auth.redirectUri;
    this.timeoutservice.isTimeoutState = true;
  }

}
