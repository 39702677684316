import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { first, mergeMap, Observable } from "rxjs";
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { AppConfigService } from './app-config.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly max_refresh_attempts = 3;
  private refreshAttempts = 0;
  constructor(private rebarAuthService: RebarAuthService) {

  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let changedRequest = req;
    var apiToken: any = '';
    var tokenExpiryTime: any = localStorage.getItem('apitokenexpiry');
    if (tokenExpiryTime != '') {
      var expiryTime = new Date(tokenExpiryTime);
      if (expiryTime > new Date()) {
        apiToken = localStorage.getItem("apitoken");
      }
      else {
        this.rebarAuthService.refreshToken();
        apiToken = localStorage.getItem("apitoken");
      }
    }
    else {
      this.rebarAuthService.refreshToken();
    }
    const headerSettings: any = {};
    for (const key of req.headers.keys()) {
      headerSettings[key] = req.headers.getAll(key);
    }


    if (apiToken != '') {

      headerSettings['Authorization'] = 'Bearer ' + apiToken;

    }
    const newHeader = new HttpHeaders(headerSettings);

    changedRequest = req.clone({
      headers: newHeader
    });
    return next.handle(changedRequest).pipe(catchError((error: any) => {
      if (error.status === 401 || error.status === 403) {     
        if (this.refreshAttempts < this.max_refresh_attempts) {
          this.refreshAttempts++;
          return this.handleUnauthorizedError(changedRequest, next);
        }
        else{
          console.error('Exceeded max token refresh attempts');
        }
      }
      else {
        return error;
      }
    })
    ) as Observable<HttpEvent<any>>;
  }
  private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return new Observable(observer => {
      this.rebarAuthService.refreshTokenObservable().subscribe(
        (newToken: string) => {
          const authReq = request.clone({
            setHeaders: {
              Authorization: `Bearer ${newToken}`
            }
          });
          const newRequest = next.handle(authReq);
          newRequest.subscribe(
            (event: HttpEvent<any>) => {
              observer.next(event);
            },
            (error: any) => {
              console.error('Error in handling refreshed token request:', error);
              observer.error(error);
            },
            () => {
              observer.complete();
            }
          );
        },
        (refreshError: any) => {
          console.error('Token refresh failed:', refreshError);
          observer.error(refreshError);
        }
      );
    });
  }
}
