import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { RebarAuthService } from '../rebarauth/rebar.auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppTimeoutService {
  private timeoutDuration: number = 60 * 60 * 1000; // 1 hour in milliseconds
  private activitySubject = new Subject<void>();
  private timeoutTimer!: Observable<number>;
  private timeoutId: any;
  private logoutSubject = new Subject<void>();
  private logoutKey = 'userLogout';
  private timeoutSubject = new Subject<void>();
  private readonly INACTIVITY_TIMEOUT = 60 * 60 * 1000; // 1 hour in milliseconds
  private readonly ACTIVITY_CHECK_INTERVAL = 5000; // 5 seconds in milliseconds
  private lastActivityTime: any;
  constructor(private rebarAuthService: RebarAuthService,
    private auth: MsalService) {
    this.resetapplicationTimer();
    this.startapplicationActivityCheck();
  }
  private resetapplicationTimer(): void {
    localStorage.setItem(this.logoutKey, 'false');
    localStorage.setItem('lastActivityTime', Date.now().toString());
    //this.lastActivityTime = Date.now();
  }

  private startapplicationActivityCheck(): void {
    setInterval(() => {
      this.checkAppTimeoutActivity();
    }, this.ACTIVITY_CHECK_INTERVAL);
  }

  private checkAppTimeoutActivity(): void {
    const currentTime = Date.now();
    const lastActivityTime = parseInt(localStorage.getItem('lastActivityTime') || '0', 10);
    const timeSinceLastActivity = currentTime - lastActivityTime;

    if (timeSinceLastActivity >= this.INACTIVITY_TIMEOUT) {
      console.log('User inactive for too long. Perform timeout actions.');
      this.rebarAuthService.applogout();
    } 
  }

  public onActivity(): void {
    // Call this method whenever there is user activity
    this.resetapplicationTimer();
  }
  

  //private resetTimer(): void {
  //  clearTimeout(this.timeoutId);
  //  this.timeoutId = setTimeout(() => {
  //    try {
  //      localStorage.setItem(this.logoutKey, 'true');
  //      this.timeoutSubject.next();
  //      this.applicationLogout();
  //    }
  //    catch (error) {
  //      console.error('Timeout error' + error);
  //    }
  //   }, this.timeoutDuration);

  //}
  //onTimeout(): Observable<void> {
  //  return this.timeoutSubject.asObservable();
  //}
  //private lastActivityTime: any;
  //public activityDetected(): void {
  //  localStorage.setItem(this.logoutKey, 'false');
  //  this.lastActivityTime = Date.now();
  //  this.activitySubject.next();
  //  this.resetTimer();
  //}
  //public isSessionTimedOut(): boolean {
  //  const currentTime = Date.now();
  //  const elapsedTime = currentTime - this.lastActivityTime;
  //  if (elapsedTime > this.timeoutDuration) {
  //    console.log('timedout');
  //    localStorage.setItem(this.logoutKey, 'true');
  //  }
  //  return elapsedTime > this.timeoutDuration;

  //}


  public getTimeoutTimer(): Observable<number> {
    return this.timeoutTimer;
  }
  applicationLogout() {
    localStorage.removeItem('apitokenexpiry');
    localStorage.removeItem('apitoken');
    sessionStorage.removeItem('msal.idtoken');
    sessionStorage.removeItem('ddrumsdk.tokenclaims');
    sessionStorage.removeItem('ddrumsdk.idtoken');
    localStorage.setItem(this.logoutKey, 'true');
    const accounts = this.auth.instance.getAllAccounts();
    this.auth.instance.setActiveAccount(accounts[0]);

    const logoutRequest = {
      account: this.auth.instance.getAllAccounts()[0]
    }
    setTimeout(() => {
      try {
        localStorage.setItem(this.logoutKey, 'true');
        console.log('timedout');
        this.rebarAuthService.logoutRedirect(logoutRequest);
        this.logoutSubject.next();
 
      } catch (error) {
        console.error('Error setting localStorage:', error);
      }
    }, 1000);
  }
}
