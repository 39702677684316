<app-header></app-header>

<div class="d-flex flex-wrap nominations-header" [ngClass]="isAdmin ? 'hasAdminAccess' : 'noAdminAccess'">
  <div class="title-text">Nominations</div>
  <!--<div class="badge badge-pill badge-primary">+ Create New Form</div>-->
  <div><app-createnominationform></app-createnominationform></div>
</div>

<div class="d-flex bd-highlight align-items-center menus"
     [ngClass]="isAdmin ? 'hasAdminAccess' : 'noAdminAccess' || isAdmin ? 'hasAdminAccess' : 'noAdminAccess' ">
  <button class="p-2 bd-highlight navbar-brand filter"
          [ngClass]="{ open: isFilterModalOpen, filterApplied: isGridFilterHasChangesApplied }" id="filterButton"
          placement="bottom" [ngbPopover]="popOverFilter" popoverClass="filter-popover" [autoClose]="false"
          #popOver="ngbPopover" triggers="manual" (click)="$event.stopPropagation(); adminPopovers('filter', 'Open')">
    <img class="icon-img img-fluid filter" [src]="filterIcon" />
    Filter
  </button>


  <ng-template #popOverFilter>
    <div class="d-flex">
      <div class="filter-table-1">
        <div class="p-2 flex-fill bd-highlight formstatus-title">
          Form Status
        </div>
        <div class="formstatus-dropdown">
          <mat-form-field>
            <label class="date-range-label"
                   [hidden]="formStatus.length != optionFormStatus.length">{{formStatusPlaceholder}}</label>
            <mat-icon class="expand_icon">expand_more</mat-icon>
            <mat-select #fs [formControl]="filter1" multiple [(value)]="formStatus" panelClass="formstatusfilter"
                        [ngClass]="formStatus.length == optionFormStatus.length ? 'hidePlaceholder' : 'showPlaceholder'">
              <mat-option class="formstatus" *ngFor="let item of optionFormStatus" [value]="item.FormStatusCd">
                {{ item.FormStatusDesc }}
              </mat-option>
              <div class="d-flex flex-row-reverse filterdropdown-menu-options">
                <button class="p-2 badge badge-pill apply" (click)="onFilterApply('FormStatus', 'Apply'); fs.close()">
                  Apply
                </button>
                <button class="pr-4 reset" (click)="onFilterApply('FormStatus', 'Reset')">
                  Reset
                </button>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div style="margin-top: 10px" class="p-2 flex-fill bd-highlight nomineesmarket-title">
          Nominee's Market
        </div>
        <div class="nomineesmarket-dropdown">
          <mat-form-field>
            <label class="date-range-label"
                   [hidden]="nomineesMarket.length != optionNomineesMarket.length">{{nomineesMarketPlaceholder}}</label>
            <mat-icon class="expand_icon">expand_more</mat-icon>
            <mat-select #nm [formControl]="filter2" multiple [(value)]="nomineesMarket"
                        [ngClass]="nomineesMarket.length == optionNomineesMarket.length ? 'hidePlaceholder' : 'showPlaceholder'">
              <mat-option class="nomineesmarket" *ngFor="let item of optionNomineesMarket" [value]="item.MarketId">
                {{
                item.MarketDesc
                }}
              </mat-option>
              <div class="d-flex flex-row-reverse dropdown-menu-options">
                <button class="p-2 badge badge-pill apply"
                        (click)="onFilterApply('NomineesMarket', 'Apply'); nm.close()">
                  Apply
                </button>
                <button class="pr-4 reset" (click)="onFilterApply('NomineesMarket', 'Reset')">
                  Reset
                </button>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="filter-table-2" style="margin-left: 43px">
        <div class="p-2 flex-fill bd-highlight submitdate-title">
          Submit Date
        </div>
        <div class="submitdaterange" [class.isCalendarOpened]="this.isCalendarOpened"
             (click)="submitDateEvents('submitdaterange click', dtStart)"
             (focus)="isFilterCalendarOpened(); submitDateEvents('submitdaterange focus',dtStart)" (focusout)="isFilterCalendarOpened(); submitDateEvents('submitdaterange focusout',dtStart)">

          <mat-form-field #submitDateField (click)="submitDateEvents('matformfield click',dtStart)"
                          [ngClass]=" this.isSubmitDateInvalid ? 'submitDateInvalid' :''">
            <label class="date-range-label" [hidden]="submitDateClicked || submittedDateStart != undefined">
              Enter a date range
            </label>
            <mat-date-range-input [rangePicker]="picker"
                                  [hidden]="!submitDateClicked && submittedDateStart == undefined"
                                  [ngClass]=" this.isSubmitDateInvalid ? 'submitDateInvalid' :''" [min]="datePickerFilterYear('min')"
                                  (dateChange)="submitDateChange('start',dtStart.value)" (change)="submitDateChange('start',dtStart.value)">
              <input #dtStart matStartDate [(ngModel)]="submittedDateStart"
                     (dateChange)="submitDateChange('start',dtStart.value)" placeholder="Start Date"
                     (keypress)="submitDateChange('start',dtStart.value)" />
              <input #dtEnd matEndDate [(ngModel)]="submittedDateEnd" (dateChange)="submitDateChange('end',dtEnd.value)"
                     placeholder="End Date" (keypress)="submitDateChange('end',dtEnd.value)" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker" id="submitDatePickerToggle" class="text-start"
                                   (click)="isFilterCalendarOpened()">
              <mat-icon matDatepickerToggleIcon class="calendar-icon">
                date_range
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>


        <div style="margin-top: 10px" class="p-2 flex-fill bd-highlight nomineesmarketunit-title">
          Nominee's Market Unit
        </div>
        <div class="nomineesmarketunit-dropdown">
          <mat-form-field>
            <label class="date-range-label"
                   [hidden]="allMarketUnits.length != optionAllMarketUnits.length || allMarketUnits.length == 0">{{allMarketUnitsPlaceholder}}</label>
            <mat-icon class="expand_icon">expand_more</mat-icon>
            <mat-select #mu [formControl]="filter3" multiple [(value)]="allMarketUnits"
                        [ngClass]="allMarketUnits.length == optionAllMarketUnits.length ? 'hidePlaceholder' : 'showPlaceholder'">
              <mat-option class="nomineesmarketunit" *ngFor="let item of optionAllMarketUnits" [value]="item.MUId">
                {{
                item.MarketDesc
                }}
              </mat-option>
              <div class="d-flex flex-row-reverse dropdown-menu-options">
                <button class="p-2 badge badge-pill apply" (click)="onFilterApply('MarketUnits', 'Apply'); mu.close()">
                  Apply
                </button>
                <button class="pr-4 reset" (click)="onFilterApply('MarketUnits', 'Reset')">
                  Reset
                </button>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div style="margin-top: 10px" class="p-2 bd-highlight qad-radio eqad-title">
      EQAD or QAD
    </div>
    <div class="flex-row input-group radio-choices">
      <div class="custom-control custom-radio custom-control-inline">
        <mat-radio-group [(ngModel)]="certification" [value]="certification" name="single_img_radio">
          <mat-radio-button #QAD value="2" (click)="onRadioClick(QAD)">QAD</mat-radio-button>
          <mat-radio-button #EQAD value="1" (click)="onRadioClick(EQAD)">EQAD</mat-radio-button>
          <mat-radio-button #ALL value="0" (click)="onRadioClick(ALL)" [checked]="true">All</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="d-flex flex-row-reverse filter-menu-options">
      <button class="p-2 badge badge-pill apply" (click)="onGridFilterApplyReset('Apply')"
              [disabled]="isSubmitDateInvalid" [ngClass]="isSubmitDateInvalid ? 'disabledApplyButton' :''">
        Apply
      </button>
      <button class="pr-4 pl-4 reset" (click)="onGridFilterApplyReset('Reset')">
        Reset
      </button>
      <button class="pr-4 cancel" (click)="$event.stopPropagation(); adminPopovers('cancel', 'Close')">
        Cancel
      </button>
    </div>
  </ng-template>

  <button class="p-2 bd-highlight navbar-brand select" [ngClass]="{ open: isColumnModalOpen }" id="selectButton"
          placement="bottom" [ngbPopover]="popOverSelectColumns" popoverClass="select-popover" [autoClose]="false"
          triggers="manual" #popOverSelectColumn="ngbPopover"
          (click)="$event.stopPropagation(); adminPopovers('selectColumns', 'Open')">
    <img class="icon-img img-fluid select" [src]="selectIcon" />
    Select Columns
  </button>
  <ng-template #popOverSelectColumns>
    <div class="selectColumn-titletext">Select Columns</div>
    <div class="search-column">
      <input class="search-Icon" type="text" [(ngModel)]="selectColumnSearchChange"
             (keyup)="onSelectColumnSearchChange(selectColumnSearchChange)" />
    </div>

    <div class="container select-columns">
      <ul>
        <li *ngFor="let item1 of optionAllColumnsField" [style.display]="item1.display">
          <!-- *ngFor="isSelectColumnReset ? let item of optionAllColumnsField : let item of optionAllColumnsField; let i=index" -->
          <label class="container menu-select no-select">
            <!-- <label
            class="container menu-select no-select"
            *ngFor="let item1 of optionAllColumnsField;  let i1=index; let item2 of optionSelectedColumnsField;  let i2=index "
            > -->
            <input type="checkbox" (change)="onSelectColumnChange(item1, $event)" [value]="item1.ColumnDisplayName"
                   [checked]="item1.CheckIndGrid ? true : false" [disabled]="item1.OrderNbrGrid == 1 ? true : false"
                   [ngClass]="item1.OrderNbrGrid == 1 ? 'disabled' : ''" />
            <!-- {{optionAllColumnsField | json}} -->
            <!-- <input
              type="checkbox"
              (change)="isSelectColumnReset ? onSelectColumnChange(item2, i2, $event):  onSelectColumnChange(item1, i1, $event)"
              [value]="isSelectColumnReset ? item2.ColumnDisplayName : item1.ColumnDisplayName"
              [checked]="isSelectColumnReset ? (item2.CheckIndGrid ? true : false) : (item1.CheckIndGrid ? true : false)"
              [disabled]="isSelectColumnReset ? (item2.OrderNbrGrid == 1 ? true : false) : (item1.OrderNbrGrid == 1 ? true : false)"
              [ngClass]="isSelectColumnReset ? (item2.OrderNbrGrid == 1 ? 'disabled' : '') : (item1.OrderNbrGrid == 1 ? 'disabled' : '')"
              #someInput
            />{{isSelectColumnReset ? 'hehe2' + item2.ColumnDisplayName :  'hehe1' +   item1.ColumnDisplayName }} -->
            <!-- [checked]="item.CheckIndGrid ? true : false" -->
            <span class="checkmark" [ngStyle]="{
            'background-color':
               (isSelectColumnReset ? item1.ColumnDisplayName: item1.ColumnDisplayName)  == 'Nominee\'s Enterprise ID'
                ? '#cccccc'
                : ''
          }"></span>
            {{ item1.ColumnDisplayName }}
          </label>
        </li>
      </ul>
    </div>
    <div class="d-flex flex-row-reverse select-menu-options">
      <button class="p-2 badge badge-pill apply" (click)="onSelectColumnsApplyReset('Apply')">
        Apply
      </button>
      <button class="pr-4 reset" (click)="onSelectColumnsApplyReset('Reset')">
        Reset
      </button>
    </div>
  </ng-template>

  <div>
    <app-export [FormStatus]="FormStatusCd" [NomineesMarket]="NomineesMarket" [MarketUnits]="MarketUnits"
                [SubmittedDateStart]="prevSubmittedDateStart" [SubmittedDateEnd]="prevSubmittedDateEnd"
                [Certification]="Certifications"></app-export>
  </div>

  <div class="p-2 flex-grow-1 bd-highlight navbar-brand search">
    <input type="text" placeholder="Search" [(ngModel)]="inputSearch" [value]="inputSearch"
           (keyup)="onKeyupSearch()" (focus)="onSearchFocusIn()" (focusout)="onSearchFocusOut()" />
    <img class="icon-img img-fluid clear" [src]="clearIcon" *ngIf="!hideSearchCloseIcon" (click)="clearSearchField()" />
  </div>
  <div class="bd-highlight navbar-brand pages">
    <button [disabled]="gridCurrentPage !== 1 ? false : true">
      <img class="icon-img img-fluid prev" [src]="prevIcon" (click)="gridPreviousPage()" />
    </button>
  </div>
  <div class="bd-highlight navbar-brand ag-grid-pages">
    <input type="number" name="currentPage" id="currentPage" [(value)]="gridCurrentPage" [(ngModel)]="gridCurrentPage"
           (focusout)="onCurrentPageEnter($event)" (keydown.enter)="onCurrentPageEnter($event)" [min]="0"
           [max]="gridTotalPages" [maxLength]="gridTotalPages.length" (onKeyPress)="onCurrentPageEnter($event)"
           limit-to="gridTotalPages.length" />
    <label class="ofText">of</label> {{ gridTotalPages }}
  </div>
  <div class="bd-highlight navbar-brand pages">
    <button [disabled]="(gridTotalPages !== gridCurrentPage || gridTotalPages !== 0) ? false : true">
      <img class="icon-img img-fluid next" [src]="nextIcon" (click)="gridNextPage()" />
    </button>
  </div>
</div>
<!-- AG Grid Angular Component -->
<ag-grid-angular class="ag-theme-alpine qad-admin-grid" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
                 [rowData]="rowData$" [animateRows]="true" (gridReady)="onGridReady($event)" [pagination]="true"
                 [paginationPageSize]="gridPaginationPageSize" [suppressPaginationPanel]="true" [cacheQuickFilter]="true"
                 [ngClass]="isAdmin ? 'hasAdminAccess' : 'noAdminAccess'" [enableCellTextSelection]="false"
                 suppressCellFlash="true" [icons]="gridSortIcons" [alwaysMultiSort]="true"
                 [suppressDragLeaveHidesColumns]="true"></ag-grid-angular>
<a id="btnlogout" href="javascript:void(0)" style="display: none;" (click)="applicationLogout()">Logout</a>
<app-footer></app-footer>

