import { Component, ElementRef, OnInit, HostListener, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';


import { MsalService } from '@azure/msal-angular'
import {
  IHeaderSupportSection,
  INotificationsSection,
  IAdditionalToolsSection,
} from '../../core/models/HeaderSection';
import { NgbModal, NgbModule, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import $ from 'jquery';
import { AdminPageComponent } from 'src/app/qadnomination/pages/admin-page/admin-page.component';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})

//  interface headerSupportSection {
//  SupportDesc: string;
//  SupportLinkDesc: string;
//  EmailSubj:string
//}
export class HeaderComponent implements OnInit {
  acnLogoSrc = 'assets/img/header/acc_gt_solid_white_rgb.png';
  acnLogoAlt = 'acn-logo';
  notificationSrc = 'assets/img/header/notifications_white.svg';
  additionalToolsSrc = 'assets/img/header/additional_tools.png';
  supportIconSrc = 'assets/img/header/support_icon.png';
  reddot = 'assets/img/header/reddot.png';
  textTitle: string = '';
  supportSectionValues: IHeaderSupportSection[] = [];
  notificationValues: INotificationsSection[] = [];
  additionalToolValues: IAdditionalToolsSection[] = [];
  notifCount: boolean = false;
  notificationTextContent: string = '';
  isheaderDataLoaded: boolean = false;
  readCount: number = 0;
  feedbackLink: string = '';
  feedbackTitle: string = '';
  currentUserName: string = '';
  isAdmin = false;
  notifModal: any = null;
  isNotificationModalOpen: boolean = false;
  isHelpModalOpen: boolean = false;
  isToolsOpen: boolean = false;


  constructor(
    public commonService: CommonService,
    private modalService: NgbModal,
    private rebarAuthService: RebarAuthService,
    private auth: MsalService

  ) {

  }
  @ViewChild('popOverNotifications') public popOverNotifications!: NgbPopover;
  @ViewChild('popOverHelps') public popOverHelps!: NgbPopover;
  @ViewChild('popOverTool') public popOverTool!: NgbPopover;
  @ViewChild('notifContent', { static: true }) private notifContent!: NgbPopover;
  ngOnInit(): void {
    setTimeout(() => {
      this.commonService.checkUserRole().subscribe((usr: string) => {
        if (usr === "Administrator" || usr === "Reviewer" || usr === "Nominee" || usr === "ReadOnly") {
          this.isAdmin = true;
          this.getAdminAccess();
        }
      });
    }, 2000);

  }

  getSupportSectionDetails() {
    this.commonService.getHeaderSupportSection().subscribe((response) => {
      this.supportSectionValues = response;
      this.feedbackLink =
        this.supportSectionValues[
          this.supportSectionValues.length - 1
        ].SupportLinkDesc;
      this.feedbackTitle =
        this.supportSectionValues[
          this.supportSectionValues.length - 1
        ].SupportDesc;
    });
  }
  hideSupportLink(link: any): boolean {
    return link.SupportDesc !== 'Nomination FAQs' && link.SupportDesc !== 'Submit Questions';
  }
  getNotificationDetails() {
    //const entId = 's.kesava.reddy';
    const params = { EnterpriseId: this.currentUserName, QADAdminReportId: 3 };
    this.commonService.getNotificationsSection(params).subscribe((response) => {
      this.notificationValues = response;
      if (this.notificationValues.length > 0) {
        this.notifCount = true;
        this.notificationValues.forEach((item) => {
          if (item.ReadStatus == 0) {
            this.readCount = this.readCount + 1;
          }
        });
      } else {
        this.notifCount = false;
      }
    });
  }

  getAdditionalToolsSection() {
    this.commonService.getAdditionalToolsSection().subscribe((response) => {
      this.additionalToolValues = response;
      console.log(JSON.stringify(response));
    });
  }

  FillNotificationBox(
    index: any,
    notificationText: string,
    readStatus: number
  ) {
    console.log('read Status: ' + readStatus);
    this.notificationTextContent = notificationText;
    this.notifModal = this.modalService.open(this.notifContent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'notif-modal',
      backdrop: false
    });
    this.popOverNotifications.close();
    const hid = $('#hidNotificationId' + index).val();
    /* const entId = 's.kesava.reddy';*/
    const params = { NotificationId: hid, EnterpriseId: this.currentUserName };
    this.commonService
      .setNotificationReadStatus(params)
      .subscribe((response) => {
        if (readStatus == 0) {
          this.readCount = this.readCount - 1;
        }
      });
  }
  openNotifdialog(notifContent: any) {
    this.modalService.open(notifContent, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: false,
      size: 'lg',
    });
  }

  getAdminAccess(): any {
    if (this.isAdmin) {
      this.textTitle = this.commonService.constants.headerTitle;
      this.currentUserName = this.commonService.getCurrentUsername();
      this.getSupportSectionDetails();
      this.getNotificationDetails();
      this.getAdditionalToolsSection();
      this.isheaderDataLoaded = true;
    }
  }

  closeNotifPopupDialogue(): void {
    this.modalService.dismissAll();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const targets = event.target as HTMLElement;
    if (targets.className.search('modal-') == -1 && targets.className.toString() != "") {
      if (this.notifModal) {
        this.notifModal.close();
      }
    }
  }

  openHelpPopup() {
    this.commonService.onNotHelpSupportOpen();
    this.isHelpModalOpen = true;
    this.popOverHelps.open();
  }

  openNotPopup() {
    this.commonService.onNotHelpSupportOpen();
    this.isNotificationModalOpen = true;
    this.popOverNotifications.open();
  }

  openToolsPopup() {
    this.commonService.onNotHelpSupportOpen();
    this.isToolsOpen = true;
    this.popOverTool.open();
  }
  applicationLogout() {
    this.rebarAuthService.applogout();
  }
}
