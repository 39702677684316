<button class="p-2 bd-highlight navbar-brand export"
        (click)="openVerticallyCentered(content)"
        [ngClass]="{  exportOpened: isModalOpen }">
  <img class="icon-img img-fluid export" [src]="exportIcon" />
  Export
</button>


<ng-template #content let-modal>
  <div class="container-fluid export-modal">
    <div class="export-header">
      <div class="export-title">Export Nomination Data</div>
      <div class="export-subtitle">Please check the fields you want to export.</div>
    </div>
    <div class="d-flex export-body" style="height:540px;overflow-y:scroll;margin-top:20px;">
      <div class="d-flex align-content-around flex-wrap row-1">
        <div class="general-column">
          <div class="generalColumn-titletext">General / Other</div>
          <label class="container menu-export">
            <input class="exportcolumn" data="a1" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            Form Status
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="a2" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            QAD or EQAD
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="a3" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            Market Unit Quality Lead Enterprise ID
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="a4" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            Nominator's Enterprise ID
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="a5" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            Nominator's Market(s)
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="a6" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            Created Date
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="a7" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            Created By
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="a8" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            Rationale for Nomination
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="a9" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            Notes
          </label>
        </div>
        <div class="qad-status">
          <div class="qadStatusColumn-titletext">QAD Status</div>
                    <label class="container menu-export">
            <input class="exportcolumn" data="b1" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            QAD Submitted Date
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="b2" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            QL Approved Date (QAD)
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="b3" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            QAD SC Review Date
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="b4" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            QAD SC Decision
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="b5" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            QAD SC Reconsider Date
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="b6" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            QAD Certification Date
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="b7" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            QAD Registration Cancelled Date
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="b8" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            QAD Reactivate Date
          </label>
          
        </div>
        <div class="eqad-status">
          <div class="eqadStatus-titletext">EQAD Status</div>
                    <label class="container menu-export">
            <input class="exportcolumn" data="c1" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            Nominated for EQAD Date
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="c2" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            EQAD Exception Approved
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="c3" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            EQAD Submitted Date
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="c4" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            QL Approved Date (EQAD)
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="c5" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            EQAD SC Review Date
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="c6" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            EQAD SC Decision
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="c7" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            EQAD SC Reconsider Date
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="c8" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            EQAD Certification Date
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="c9" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            EQAD Registration Cancelled Date
          </label>
          <label class="container menu-export">
            <input class="exportcolumn" data="c10" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
            <span class="checkmark"></span>
            EQAD Reactivate Date
          </label>
        </div>
        <div class="d-flex align-content-around flex-wrap row-2">
          <div class="nominee's-info">
            <div class="nomineeInfo-titletext">Nominee's Info</div>
            <label class="container menu-export nominee">
              <input class="exportcolumn" data="d1" type="checkbox"
                     checked="checked"
                     [disabled]="true" />
              <span class="checkmark"></span>
              Nominee's Enterprise ID
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="d2" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              Nominees's Market
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="d3" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              Nominees's Market Unit
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="d12" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              Nominee's Market Unit - Americas
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="d6" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              Country
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="d7" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              City
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="d8" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              Service/Group
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="d4" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              Areas of Expertise
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="d5" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              Skills
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="d9" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              # of Opp Assignments
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="d10" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              # of Del Assignments
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="d11" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              Total # of Assignments
            </label>
          </div>
          <div class="credentialing">
            <div class="credentialing-titletext">Credentialing</div>
            <label class="container menu-export">
              <input class="exportcolumn" data="e1" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              Training Enrollment Date
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="e5" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              Training Session Date
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="e2" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              Training Completion Date
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="e3" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              Mastery Test Completion Date
            </label>
            <label class="container menu-export">
              <input class="exportcolumn" data="e4" type="checkbox" checked="checked" (change)="checkboxchange($event)" />
              <span class="checkmark"></span>
              Case Study Assessment Completion Date
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row-reverse export-footer">
    <button type="button"
            class="badge badge-pill badge-primary export-button"
            (click)="exportReport()">
      Export
    </button>
    <button class="pr-4 export-cancel"
            (click)="$event.stopPropagation(); closeVerticallyCentered()">
      Cancel
    </button>

    <div class="loading-overlay" *ngIf="isLoading">
      <div class="loading-spinner"></div>
      <div style=" padding-left: 6px;">Loading...</div>
    </div>
  </div>
</ng-template>
