<div class="timeout-page">
  <!--<p class="qad-title">QAD Nomination Tool: Session Timeout</p>-->
  <div class="body">
    <p>
      Your session has timed out due to inactivity.  <a href={{applicationurl}}>
        Click here
      </a> to return to the QAD Nomination Tool home page.
    </p>
  </div>
</div>
