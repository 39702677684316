import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { REBAR_AUTH_GUARD } from './core/rebarauth/rebar.auth.module';
import { AdminPageComponent } from './qadnomination/pages/admin-page/admin-page.component';
import { QadnominationFormComponent } from './qadnomination/pages/qadnomination-form/qadnomination-form.component';
import { NotAuthorizedComponent } from './qadnomination/pages/not-authorized/not-authorized.component';
import { AuthGuard } from './core/services/nomineeauth.guard';
import { AdminAuthGuard } from './core/services/adminauth.guard';
import { TimeoutComponent } from './qadnomination/pages/timeout/timeout.component';
const routes: Routes = [

 // { path: '', redirectTo: 'admin', pathMatch: 'full' },
  {
    path: 'admin',
    component: AdminPageComponent,
    canActivate: [REBAR_AUTH_GUARD, AdminAuthGuard],
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
    canActivate: [REBAR_AUTH_GUARD],
  },
  {
    path: 'session-timeout',
    component: TimeoutComponent,
    canActivate: [REBAR_AUTH_GUARD, AdminAuthGuard],
  },
  {
    path: ':enterpriseid',
    component: QadnominationFormComponent,
    canActivate: [REBAR_AUTH_GUARD, AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
